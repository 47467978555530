#report.table {
  width: 100%;
  flex-grow: 1;
}
.containerTable {
  overflow: auto;
  width: 100%;
  /* white-space: nowrap; */
  height: 80vh;
  max-height: 80vh;
  min-height: 80vh;
}
.layoutTable {
  table-layout: fixed;
  min-width: 2%;
  width: 2%;
}
.layoutTable2 {
  table-layout: fixed;
  min-width: 10%;
  width: 10%;
}
.layoutTable3 {
  table-layout: fixed;
  min-width: 15%;
  width: 15%;
}
.layoutTable4 {
  table-layout: fixed;
  min-width: 28%;
  max-width: 28%;
  width: 28%;
}
