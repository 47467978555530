.table-list-group-item-action {
  width: 100%;
  color: #050505;
  text-align: inherit;
}
.table-list-group-item-action:hover,
.table-list-group-item-action:focus {
  z-index: 1;
  color: #f80707;
  text-decoration: none;
  background-color: #e7e7e7;
}
.table-list-group-item-action:active {
  color: #f00f0f;
  background-color: #e9ecef;
}
