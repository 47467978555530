.container {
  background-image: url("/src/images/BG.png");
  min-width: 100%;
  min-height: 100%;
  position: fixed;
  background-size: cover;
  background-position: center;
}
#login.logo {
  width:30%;
  height:30%;
}
