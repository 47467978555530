body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Kanit";
  src: local("Kanit"),
    url(./fonts/Kanit/Kanit-Black.ttf) format("truetype");
}
@font-face {
  font-family: "Kanit";
  src: local("Kanit"),
    url(./fonts/Kanit/Kanit-BlackItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Kanit";
  src: local("Kanit"),
    url(./fonts/Kanit/Kanit-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Kanit";
  src: local("Kanit"),
    url(./fonts/Kanit/Kanit-BoldItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Kanit";
  src: local("Kanit"),
    url(./fonts/Kanit/Kanit-ExtraBold.ttf) format("truetype");
}
@font-face {
  font-family: "Kanit";
  src: local("Kanit"),
    url(./fonts/Kanit/Kanit-ExtraBoldItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Kanit";
  src: local("Kanit"),
    url(./fonts/Kanit/Kanit-ExtraLight.ttf) format("truetype");
}
@font-face {
  font-family: "Kanit";
  src: local("Kanit"),
    url(./fonts/Kanit/Kanit-ExtraLightItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Kanit";
  src: local("Kanit"),
    url(./fonts/Kanit/Kanit-Italic.ttf) format("truetype");
}
@font-face {
  font-family: "Kanit";
  src: local("Kanit"),
    url(./fonts/Kanit/Kanit-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Kanit";
  src: local("Kanit"),
    url(./fonts/Kanit/Kanit-LightItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Kanit";
  src: local("Kanit"),
    url(./fonts/Kanit/Kanit-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Kanit";
  src: local("Kanit"),
    url(./fonts/Kanit/Kanit-MediumItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Kanit";
  src: local("Kanit"),
    url(./fonts/Kanit/Kanit-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "Kanit";
  src: local("Kanit"),
    url(./fonts/Kanit/Kanit-SemiBoldItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Kanit";
  src: local("Kanit"),
    url(./fonts/Kanit/Kanit-Thin.ttf) format("truetype");
}

@font-face {
  font-family: "Kanit";
  src: local("Kanit"),
    url(./fonts/Kanit/Kanit-ThinItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Kanit";
  src: local("Kanit"),
    url(./fonts/Kanit/Kanit-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://example.com/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url(https://example.com/MaterialIcons-Regular.woff2) format("woff2"),
    url(https://example.com/MaterialIcons-Regular.woff) format("woff"),
    url(https://example.com/MaterialIcons-Regular.ttf) format("truetype");
}

.MuiTableCell-root {
  border-bottom: none !important;
}

a {
  text-decoration: none !important;
}
